import React, { FunctionComponent, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
import { fetch_all_with_count, fetch_one } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';
import { currentPracticeAtom } from '../../../lib/practice';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import GroupsIcon from '@mui/icons-material/Groups';
import RepeatIcon from '@mui/icons-material/Repeat';

import {
  Avatar,
  Button as Btn,
  DialogActions,
  Grid,
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import {
  DataGrid,
  DatePicker,
  Dt,
  Fab,
  IconButton,
  Page,
  Switcher,
  TextInput,
  Typography
} from '../../../components/v2/styled';
import { Base } from '../../../components/v2/dialogs/Base';
import { SnackDestroyed } from '../../../components/v2/snacks/Destroyed';
import { SnackSaved } from '../../../components/v2/snacks/Saved';

import { Workshop } from '../../../models/Workshop';

import HelpersPractice from '../../../actions/helpers/practice';

// const { DateTime } = require("luxon");

type Props = {}

export const CoachWorkshops: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  const [destroyedOpen, setDestroyedOpen] = useState<boolean>(false);
  const [activeForDestroy, setActiveForDestroy] = useState<Workshop>();
  const [destroyConfirmOpen, setDestroyConfirmOpen] = useState<boolean>(false);
  const [repeatOpen, setRepeatOpen] = useState<boolean>(false);
  const [savedOpen, setSavedOpen] = useState<boolean>(false);
  const [cancellation, setCancellation] = useState<{
    workshop_id: number;
    delete_all: boolean;
    move_after: boolean;
  }>({
    workshop_id: 0,
    delete_all: false,
    move_after: false
  });
  const [recurrence, setRecurrence] = useState<{
    workshop_id: number;
    limited_times: boolean;
    clone_times: number;
    end_date: string;
    repeat_weeks: number;
  }>({
    workshop_id: 0,
    limited_times: false,
    clone_times: 1,
    end_date: '',
    repeat_weeks: 1
  });
  
  let count = 0;
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("id DESC");

  const is_nutri_workshop = HelpersPractice.practice_modules_v3({practice: currentPractice, check: 'nutri_workshop'});

  const {
    data: objects,
    isLoading: objectsLoading,
    isError: objectsError,
    isSuccess: objectsSuccess,
    refetch: objectsRefetch,
  } = useQuery({
    queryKey: ["coach", "workshops", (currentPractice?.id || login?.practice_id), {page: page, order: order}],
    queryFn: () =>
      fetch_all_with_count<Workshop>(
        `/nl/v3/objects/fetch_all`,
        {
          object: 'workshop',
          fields: ['id', 'get_item', 'subject', 'start_time', 'max_subscribers', 'max_backups', 'get_subscriber_ids', 'get_backup_ids', 'recurrence_id'],
          order: order,
          per_page: 20,
          page: page,
          filter: {
            search: '',
            advanced: {
              practice_id: (currentPractice?.id || login?.practice_id),
              as_coach: 1
            }
          }
        },
        login
      ),
    select: (d) => {
      count = d.count;
      return d.result;
    },
    enabled: !!(currentPractice?.id || login?.practice_id),
  });







  const mutationDestroy = useMutation({
    mutationFn: (pars: {
      formData: any;
      mode?: 'destroy' | 'repeat';
    }) => {
      return fetch_one<Workshop>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/custom_action`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      // setIsEditPasswordError(false);
      // setEditPasswordSuccess(false);
      // setEditPasswordError(undefined);
      // setEditPasswordLoading(true);
      return true;
    },
    onError: (data, variables, context) => {
      // setIsEditPasswordError(true);
      // setEditPasswordError(data as any);
      // setEditPasswordLoading(false);
      return true;
    },
    onSuccess: (data, variables, context) => {
      if (variables.mode === 'destroy') setDestroyedOpen(true);
      if (variables.mode === 'repeat') setSavedOpen(true);
      setActiveForDestroy(undefined);
      setCancellation({
        workshop_id: 0,
        delete_all: false,
        move_after: false
      });
      setRecurrence({
        workshop_id: 0,
        limited_times: false,
        clone_times: 1,
        end_date: '',
        repeat_weeks: 1
      });
      objectsRefetch();
    },
  });







  let term = t('coach.models.workshops.table_title', "Overzicht workshops");
  if (!!currentPractice?.settings?.workshops_plural) {
    term = currentPractice.settings.workshops_plural;
  }

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 80 },
    { field: 'get_item', headerName: t('coach.models.workshops.item', "Afbeelding"), width: 100, renderCell: params => <>
      {!!params.row.get_item && <Avatar src={params.row.get_item} sx={{marginTop: 0.5}} />}
    </> },
    { field: 'subject', headerName: t('coach.models.workshops.subject', "Onderwerp"), width: 200 },
    { field: 'start_time', headerName: t('coach.models.workshops.start_time', "Aanvang"), width: 200, renderCell: params => <>
      <Dt d={params.row.start_time} f="DDD t" />
    </> },
    { field: 'max', headerName: t('coach.models.workshops.max_subscribers', "Maximum aanwezigen"), width: 150, renderCell: params => <>
      <Typography sx={{
        fontSize: '0.9rem',
        marginTop: '1em',
        marginBottom: '1em'
      }}>
        {params.row.get_subscriber_ids.length}/{params.row.max_subscribers}
      </Typography>
    </> },
    { field: 'max_backups', headerName: t('coach.models.workshops.max_backups', "Maximum backups"), width: 150, renderCell: params => <>
      <Typography sx={{
        fontSize: '0.9rem',
        marginTop: '1em',
        marginBottom: '1em'
      }}>
        {params.row.get_backup_ids.length}/{params.row.max_backups || 0}
      </Typography>
    </> },
    
    // { field: 'created_at', headerName: 'Created', width: 180, valueFormatter: params => DateTime.fromISO(params?.value).toFormat("f") },
  ];

  return <Page title={term} premiumGatekeeping={!!is_nutri_workshop} premiumMode='nutri_workshop'>
    <DataGrid
      action_count={4}
      actions={(params) => {
        return (<>
          <IconButton title="Open Detail" color="default" path={`/workshop/${params.row.id}`}>
            <EditIcon />
          </IconButton>
          <IconButton title="Remove" color="default" onClick={() => {
            setActiveForDestroy(params.row);
            setDestroyConfirmOpen(true);
          }}>
            <DeleteIcon />
          </IconButton>
          <IconButton title="Subscribers" color="default" path={`/workshop/${params.row.id}/subscribers`}>
            <GroupsIcon />
          </IconButton>
          <IconButton title="Repeat settings" color="default" onClick={() => {
            setRepeatOpen(true);
            setRecurrence({
              ...recurrence,
              workshop_id: params.row.id
            });
          }}>
            <RepeatIcon />
          </IconButton>
        </>);
      }}
      objectCount={count}
      getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled}`}
      objects={(objects || []).map(u => ({
        ...u
      }))}
      columns={columns}
      loader={objectsLoading}
      onSortModelChange={(a, b) => {
        let a_0_field:string = a[0].field;
        if (a_0_field === 'name') a_0_field = `last_name ${a[0].sort}, first_name`;
        setOrder([a_0_field, a[0].sort].join(' '));
      }}
      page={page}
      setPage={(p) => {setPage(p);}}
    />

    <SnackDestroyed open={destroyedOpen} setOpen={setDestroyedOpen} />
    <SnackSaved open={savedOpen} setOpen={setSavedOpen} />

    <Base
      name="repeat"
      hidden={!repeatOpen}
      title={t("coach.submenu.workshops.repeat_workshop")}
      content={<Grid container spacing={1}>
        <Grid item xs={12}>
          <Switcher
            id="limited_times"
            label={t("coach.submenu.agenda.recurrences.limited_times", "Gelimiteerd aantal herhalingen")}
            backend
            fullwidth
            value={!!recurrence.limited_times}
            onChange={(b) => {
              setRecurrence({
                ...recurrence,
                limited_times: !!b
              });
            }}
          />
        </Grid>
        {!!recurrence.limited_times && <Grid item xs={12}>
          <TextInput
            id="comment_cancel"
            caption={t("coach.models.recurrences.clone_times", "Aantal keren dat de afspraak moet voorkomen (deze meegerekend)")}
            value={recurrence.clone_times.toString()}
            onChange={(e) => {
              setRecurrence({
                ...recurrence,
                clone_times: !!e ? parseInt(e, 10) : 1
              });
            }}
          />
        </Grid>}
        {!recurrence.limited_times && <Grid item xs={12} md={6}>
          <Typography sx={{fontSize: '0.7rem'}}>{t("coach.models.recurrences.end_time")}</Typography>
          <DatePicker
            disableFuture={false}
            disablePast
            openTo="day"
            label=""
            format={"dd/LL/y"}
            // caption=''
            views={["year", "month", "day"]}
            setValue={(e) => {
              setRecurrence({
                ...recurrence,
                end_date: e
              });
            }}
            value={recurrence.end_date}
            id="rec_date"
            sx={{
              box: {
                width: 'auto',
                display: 'inline-block',
                marginRight: 0.5
              },
              field_box: {
                "svg": {
                  display: 'none'
                }
              },
              field: {
                width: 'auto',
                marginTop: 0,
                "input": {
                  textAlign: 'center'
                }
              }
            }}
          />
        </Grid>}
        <Grid item xs={12} md={6}>
          <TextInput
            id="repeat_weeks"
            caption={t("coach.models.recurrences.repeat_weeks", "Aantal weken per herhaling. 1 wilt zeggen elke week.")}
            value={recurrence.repeat_weeks.toString()}
            onChange={(e) => {
              setRecurrence({
                ...recurrence,
                repeat_weeks: !!e ? parseInt(e, 10) : 1
              });
            }}
          />
        </Grid>
        
      </Grid>}
      actions={<DialogActions>
        <Btn autoFocus onClick={() => {setRepeatOpen(false);}}>{t("coach.general.actions.cancel")}</Btn>
        <Btn onClick={(e) => {
          setRepeatOpen(false);
          mutationDestroy.mutate({formData: {
            object: 'workshop',
            class_action: 'custom_api_save_recurrence',
            ob: recurrence,
            practice_id: currentPractice?.id
          }, mode: 'repeat'});
        }}>{t("coach.general.actions.save")}</Btn>
      </DialogActions>}
      open={repeatOpen}
      setOpen={setRepeatOpen}
    />

    <Base
      name="alert"
      hidden={!open}
      title={t("general.confirm.delete_title")}
      content={<Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography>{t("general.confirm.delete_content")}</Typography>
        </Grid>
        {!!activeForDestroy?.recurrence_id && <Grid item xs={12}>
          <Switcher
            id="delete_all"
            label={t("coach.submenu.agenda.recurring_delete_all")}
            backend
            fullwidth
            value={!!cancellation.delete_all}
            onChange={(b) => {
              setCancellation({
                ...cancellation,
                delete_all: !!b,
                move_after: false
              });
            }}
          />
        </Grid>}
        {!!activeForDestroy?.recurrence_id && <Grid item xs={12}>
          <Switcher
            id="move_after"
            label={t("coach.submenu.agenda.recurring_move_after_delete")}
            disabled={!!cancellation.delete_all}
            backend
            fullwidth
            value={!!cancellation.move_after}
            onChange={(b) => {
              setCancellation({
                ...cancellation,
                move_after: !!b
              });
            }}
          />
        </Grid>}
      </Grid>}
      actions={<DialogActions>
        <Btn autoFocus onClick={() => {setDestroyConfirmOpen(false);}}>{t("general.confirm.actions.cancel")}</Btn>
        <Btn color="secondary" onClick={(e) => {
          setDestroyConfirmOpen(false);
          mutationDestroy.mutate({formData: {
            object: 'workshop',
            class_action: 'custom_api_remove',
            ...cancellation,
            workshop_id: activeForDestroy?.id
          }, mode: 'destroy'});
        }}>{t("general.confirm.actions.destroy")}</Btn>
      </DialogActions>}
      open={destroyConfirmOpen}
      setOpen={setDestroyConfirmOpen}
    />

    <Fab
      onClick={() => {
        navigate("/workshop/new", {});
      }}
    />
  </Page>;
}
