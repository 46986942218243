import React, { FunctionComponent, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
import { fetch_all, fetch_one } from "../../../lib/v31lib";
import { loginAtom } from '../../../lib/auth';
import { currentPracticeAtom } from '../../../lib/practice';

import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  MenuItem,
  Paper,
} from '@mui/material';

import {
  Button,
  DatePicker,
  Page,
  Select,
  TextInput,
  Typography
} from '../../../components/v2/styled';
import { SnackSaved } from '../../../components/v2/snacks/Saved';

import { Country } from '../../../models/Country';
import { User } from '../../../models/User';

import HelpersLanguage from '../../../actions/helpers/language';

const { DateTime } = require("luxon");

type Props = {}

export const CoachUserEdit: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  const params = useParams();
  const queryClient = useQueryClient();
  
  const [objectId, setObjectId] = useState<string | undefined>(params.id);
  const [savedOpen, setSavedOpen] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [consentAsked, setConsentAsked] = useState<string>();
  const [mainObject, setMainObject] = useState<User>({
    auth_token: '',
    email: '',
    coach_skills: []
  });
  const [item, setItem] = useState<File>();
  const [itemUrl, setItemUrl] = useState<string>();

  const [errors, setErrors] = useState<{[z:string]: boolean}>({});

  const {
    data: countries,
    isLoading: countriesLoading,
    isError: objectsError,
    isSuccess: objectsSuccess,
    // refetch: obectsRefetch,
  } = useQuery({
    queryKey: ["countries", "select_list"],
    queryFn: () =>
      fetch_all<Country>(
        `/nl/v3/pub/fetch_all`,
        {
          object: 'country',
          fields: ['id', 'name', 'iso'],
          per_page: 20,
          page: 0,
          order: "name ASC"
        }
      ),
    enabled: true,
  });
  const {
    data: object,
    isLoading: objectLoading,
    isError: objectError,
    isSuccess: objectSuccess,
    refetch: objectRefetch,
  } = useQuery({
    queryKey: ["user", (currentPractice?.id || login?.practice_id), objectId],
    queryFn: () =>
      fetch_one<User>(
        `/nl/v3/objects/fetch_all`,
        {
          object: 'user',
          id: objectId,
          fields: [
            'id', 'first_name', 'second_name', 'last_name', 'email', 'consent_status', 'call_name', 'birth_date', 'language_id', 'gender',
            'phone_fixed', 'phone_mobile', 'street', 'number', 'zip', 'city', 'country_id', 'spouse_name', 'national_number'
          ],
          practice_id: (currentPractice?.id || login?.practice_id)
        },
        login
      ),
    select: (d) => {
      return {
        ...d,
        birth_date: DateTime.fromISO(d.birth_date).toFormat("dd/LL/y")
      };
    },
    enabled: !!(currentPractice?.id || login?.practice_id) && parseInt(objectId || '', 10) > 0,
  });







  const mutationCustomAction = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<{custom_result: {success: boolean;}}>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/custom_action`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      setConsentAsked(data.custom_result.success ? 'asked' : 'error');
    },
  });
  const mutationCheckEmail = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<{exists: boolean}>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/pub/check_email`,
        pars.formData,
        undefined
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      // setIsCheckEmailError(false);
      // setCheckEmailError(undefined);
      return true;
    },
    onError: (data, variables, context) => {
      // setIsCheckEmailError(true);
      // setCheckEmailError(data as any);
      return true;
    },
    onSuccess: (data, variables, context) => {
      setEmailError(!!data.exists);
    },
  });
  const mutationSave = useMutation({
    mutationFn: (pars: {
      formData: any;
      keepOpen?: boolean
    }) => {
      return fetch_one<User>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/save`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      setSavedOpen(true);
      queryClient.invalidateQueries({ queryKey: ["coach", "users", (currentPractice?.id || login?.practice_id)] });
      if (!variables.keepOpen) navigate("/users");
      if (!!variables.keepOpen && objectId === 'new') navigate(`/user/${data.id}`);
      if (!!variables.keepOpen && objectId !== 'new') objectRefetch();
    },
  });





  const saveObject = (keep_open: boolean) => {
    let formData = new FormData();
    formData.append('object', 'user');
    formData.append('handler_id', (login?.id || "").toString());
    formData.append('fields[]', 'id');
    if (!!objectId && objectId !== 'new') formData.append('ob[id]', objectId.toString());
    if (!!objectId && objectId !== 'new') formData.append('id', objectId.toString());
    // mainObject
    if (!!item) formData.append(`ob[item]`, item);
    if (!!itemUrl) formData.append(`ob[set_item_url]`, itemUrl);
    // formData.append('ob[description]', mainObject?.description || '');
    // formData.append('ob[location_id]', mainObject?.location_id?.toString() || '');

    mutationSave.mutate({
      formData: formData,
      keepOpen: keep_open
    });
  };

  let page_title;
  if (typeof((object || {}).id) === 'undefined') {
    page_title = t("general.titles.add", "Nieuwe") + " " + t("coach.models.clients.singular", "Cliënt");
  } else {
    page_title = t("general.titles.edit", "Wijzig") + " " + (object || {}).first_name;
  }

  useEffect(() => {
    if (params.id !== 'new') objectRefetch();
  }, [params.id]);
  useEffect(() => {
    if (!!objectSuccess) setMainObject(object);
  }, [object]);
  useEffect(() => {
    let err:any = {};
    if (!mainObject.first_name) err.first_name = true;
    if (!mainObject.last_name) err.last_name = true;
    if (!mainObject.email) err.email = true;
    if (!!emailError) err.email = true;
    if (!mainObject.birth_date) err.birth_date = true;

    setErrors(err);
  }, [mainObject]);
  useEffect(() => {
    let to = setTimeout(() => {
      if (!!mainObject && !!mainObject.email) {
        mutationCheckEmail.mutate({formData: {
          email: mainObject.email,
          skip: mainObject?.id
        }});
      }
    }, 2000);

    return () => {
      clearTimeout(to);
    }
  }, [!!mainObject, mainObject?.email]);

  return <Page
    title={page_title}
    titleCrumbs={[
      {
        title: t("coach.menu.users", "Cliënten"),
        path: "/users"
      }
    ]}
  >
    <Paper sx={{padding: 2}}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h5">{t("coach.submenu.profile.email")}</Typography>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            id="email"
            caption={t("coach.models.clients.email", "E-mail")}
            error={emailError ? t("client.models.users.errors.email", "Dit adres is reeds in gebruik") : undefined}
            starred
            placeholder={t("coach.models.clients.placeholders.email", "Vul hier het emailadres in")}
            value={mainObject.email}
            onChange={(e) => {
              setMainObject({
                ...mainObject,
                email: e
              });
            }}
          />
        </Grid>

        {!!object?.consent_status?.enabled && <Grid item xs={12}>
          <Box sx={{
            padding: 2,
            border: '1px solid transparent',
            borderRadius: 1,
            borderColor: theme.palette.primary.main,
            backgroundColor: 'white',
            color: theme.palette.primary.main
          }}>
            <Typography>{t("coach.paragraphs.existing_user_consent_okay")}</Typography>
          </Box>
        </Grid>}
        {!object?.consent_status?.enabled && <Grid item xs={12}>
          <Box sx={{
            padding: 2,
            border: '1px solid transparent',
            borderRadius: 1,
            borderColor: theme.palette.warning.main,
            backgroundColor: 'white',
            color: theme.palette.warning.main
          }}>
            <Typography>{t("coach.paragraphs.existing_user_consent_not_okay")}</Typography>
            {!consentAsked && <Button
              label={t("coach.general.buttons.ask_consent")}
              id='ask_consent'
              contained
              onClick={(v) => {
                mutationCustomAction.mutate({
                  formData: {
                    object: 'user',
                    class_action: 'custom_api_ask_consent',
                    email: object?.email,
                    practice_id: (currentPractice?.id || login?.practice_id)
                  }
                });
              }}
              sx={{marginTop: 2}}
            />}
            {!!consentAsked && <Typography>{t("coach.paragraphs.existing_user_consent_asked")}</Typography>}
          </Box>
        </Grid>}


        


        {!emailError && !!object?.consent_status?.enabled && <>
          <Grid item xs={12} sx={{marginTop: 5}}>
            <Typography variant="h5">{t("coach.submenu.profile.basic")}</Typography>
            <Divider />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextInput
              id="first_name"
              caption={t("coach.models.clients.first_name", "Voornaam")}
              starred
              placeholder={t("coach.models.clients.placeholders.first_name", "Vul hier de voornaam in")}
              value={mainObject.first_name || ''}
              onChange={(e) => {
                setMainObject({
                  ...mainObject,
                  first_name: e
                });
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextInput
              id="second_name"
              caption={t("coach.models.clients.second_name", "Tussennaam")}
              placeholder={t("coach.models.clients.placeholders.second_name", "Vul hier de tussennaam in")}
              value={mainObject.second_name || ''}
              onChange={(e) => {
                setMainObject({
                  ...mainObject,
                  second_name: e
                });
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextInput
              id="last_name"
              caption={t("coach.models.clients.last_name", "Achternaam")}
              starred
              placeholder={t("coach.models.clients.placeholders.last_name", "Vul hier de achternaam in")}
              value={mainObject.last_name || ''}
              onChange={(e) => {
                setMainObject({
                  ...mainObject,
                  last_name: e
                });
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography sx={{fontSize: '0.7rem'}}>{t("coach.models.clients.birth_date", "Geboortedatum")} *</Typography>
            <DatePicker
              disableFuture
              openTo="day"
              label=""
              format={"dd/LL/y"}
              // caption=''
              views={["year", "month", "day"]}
              setValue={(e) => {
                setMainObject({
                  ...mainObject,
                  birth_date: e
                });
              }}
              value={mainObject.birth_date || ''}
              id="settings_start"
              sx={{
                box: {
                  width: 'auto',
                  display: 'inline-block',
                  marginRight: 0.5
                },
                field_box: {
                  "svg": {
                    display: 'none'
                  }
                },
                field: {
                  width: 'auto',
                  marginTop: 0,
                  "input": {
                    textAlign: 'center'
                  }
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              id="call_name"
              caption={t("coach.models.clients.call_name", "Roepnaam")}
              placeholder={t("coach.models.clients.placeholders.call_name", "Vul hier de roepnaam in")}
              value={mainObject.call_name || ''}
              onChange={(e) => {
                setMainObject({
                  ...mainObject,
                  call_name: e
                });
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Select
              fullWidth
              caption={t("coach.models.clients.gender", "Geslacht")}
              displayEmpty
              id="gender"
              value={mainObject.gender || ''}
              setValue={(v) => {
                setMainObject({
                  ...mainObject,
                  gender: !!v ? parseInt(v.toString(), 10) : undefined
                });
              }}
            >
              <MenuItem value="">--- {t("coach.models.clients.placeholders.gender", "Vul hier uw geslacht in")} ---</MenuItem>
              <MenuItem value="0">{t("general.genders.female")}</MenuItem>
              <MenuItem value="1">{t("general.genders.male")}</MenuItem>
              <MenuItem value="2">{t("general.genders.other")}</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12} md={6}>
            <Select
              fullWidth
              caption={t("coach.models.clients.language_id", "Taalkeuze")}
              displayEmpty
              id="language_id"
              value={mainObject.language_id || ''}
              setValue={(v) => {
                setMainObject({
                  ...mainObject,
                  language_id: !!v ? parseInt(v.toString(), 10) : undefined
                });
              }}
            >
              <MenuItem value="">--- {t("coach.models.clients.placeholders.language_id", "Vul hier de taalkeuze in")} ---</MenuItem>
              {HelpersLanguage.available_locales().map(lang_iso => <MenuItem value={HelpersLanguage.mapping_to_id(lang_iso)}>{lang_iso}</MenuItem>)}
            </Select>
          </Grid>


          



          <Grid item xs={12} sx={{marginTop: 5}}>
            <Typography variant="h5">{t("coach.submenu.profile.contact")}</Typography>
            <Divider />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              id="phone_fixed"
              caption={t("coach.models.clients.phone_fixed", "Vast telefoonnummer")}
              placeholder={t("coach.models.clients.placeholders.phone_fixed", "Vul hier het vaste telefoonnummer in")}
              value={mainObject.phone_fixed || ''}
              onChange={(e) => {
                setMainObject({
                  ...mainObject,
                  phone_fixed: e
                });
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              id="phone_mobile"
              caption={t("coach.models.clients.phone_mobile", "Vast telefoonnummer")}
              placeholder={t("coach.models.clients.placeholders.phone_mobile", "Vul hier het mobiele telefoonnummer in")}
              value={mainObject.phone_mobile || ''}
              onChange={(e) => {
                setMainObject({
                  ...mainObject,
                  phone_mobile: e
                });
              }}
            />
          </Grid>


          



          <Grid item xs={12} sx={{marginTop: 5}}>
            <Typography variant="h5">{t("coach.submenu.profile.address")}</Typography>
            <Divider />
          </Grid>
          <Grid item xs={12} md={8}>
            <TextInput
              id="street"
              caption={t("coach.models.clients.street", "Street")}
              placeholder={t("coach.models.clients.placeholders.street", "Vul hier de straat in")}
              value={mainObject.street || ''}
              onChange={(e) => {
                setMainObject({
                  ...mainObject,
                  street: e
                });
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextInput
              id="number"
              caption={t("coach.models.clients.number", "Huisnummer")}
              placeholder={t("coach.models.clients.placeholders.number", "Vul hier het huisnummer in")}
              value={mainObject.number || ''}
              onChange={(e) => {
                setMainObject({
                  ...mainObject,
                  number: e
                });
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextInput
              id="zip"
              caption={t("coach.models.clients.zip", "Postcode")}
              placeholder={t("coach.models.clients.placeholders.zip", "Vul hier de postcode in")}
              value={mainObject.zip || ''}
              onChange={(e) => {
                setMainObject({
                  ...mainObject,
                  zip: e
                });
              }}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <TextInput
              id="city"
              caption={t("coach.models.clients.city", "Stad / gemeente")}
              placeholder={t("coach.models.clients.placeholders.city", "Vul hier de stad of gemeente in")}
              value={mainObject.city || ''}
              onChange={(e) => {
                setMainObject({
                  ...mainObject,
                  city: e
                });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              fullWidth
              displayEmpty
              caption={t("coach.models.clients.country_id", "Land")}
              id="country"
              value={mainObject.country_id || ''}
              setValue={(v) => {
                setMainObject({
                  ...mainObject,
                  country_id: !!v ? parseInt(v.toString(), 10) : undefined
                });
              }}
            >
              <MenuItem value="">--- {t("coach.models.clients.placeholders.country_id", "Vul hier het land in")} ---</MenuItem>
              {(countries || []).map((country) => (<MenuItem value={country.id}>{country.name}</MenuItem>))}
            </Select>
          </Grid>


          



          <Grid item xs={12} sx={{marginTop: 5}}>
            <Typography variant="h5">{t("coach.submenu.profile.extra")}</Typography>
            <Divider />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              id="spouse_name"
              caption={t("coach.models.clients.spouse_name", "Naam echtgeno(o)t(e)")}
              placeholder={t("coach.models.clients.placeholders.spouse_name", "Vul hier de naam van de echtgenoot of echtgenote in")}
              value={mainObject.spouse_name || ''}
              onChange={(e) => {
                setMainObject({
                  ...mainObject,
                  spouse_name: e
                });
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextInput
              id="national_number"
              caption={t("coach.models.clients.national_number", "Rijksregisternummer of Burgerservicenummer")}
              placeholder={t("coach.models.clients.placeholders.national_number", "Vul hier de rijksregisternummer of burgerservicenummer in")}
              value={mainObject.national_number || ''}
              onChange={(e) => {
                setMainObject({
                  ...mainObject,
                  national_number: e
                });
              }}
            />
          </Grid>
        </>}






        <Grid item xs={12} sx={{
          alignContent: 'baseline',
          textAlign: 'center',
          position: 'sticky',
          bottom: '-24px',
          backgroundColor: 'white',
          zIndex: 1201
        }}>
          <Divider sx={{marginTop: 1, marginBottom: 0}} />

          {!!mutationSave.isPending && <CircularProgress />}
          {!mutationSave.isPending && <Button
            label={t("shared.buttons.save")}
            id='save'
            disabled={Object.keys(errors).length > 0}
            contained
            onClick={(v) => {
              saveObject(false);
            }}
            sx={{marginBottom: 1, marginTop: 1}}
          />}
          {!mutationSave.isPending && <Button
            label={t("shared.buttons.save_keep_open")}
            id='save_opened'
            disabled={Object.keys(errors).length > 0}
            contained
            onClick={(v) => {
              saveObject(true);
            }}
            sx={{marginLeft: 1, marginBottom: 1, marginTop: 1}}
          />}
          {!mutationSave.isPending && <Button
            label={t("shared.buttons.cancel")}
            id='cancel'
            text
            // contained
            onClick={(v) => {
              navigate("/users");
            }}
            sx={{marginLeft: 1, marginBottom: 1, marginTop: 1}}
          />}
        </Grid>
      </Grid>
    </Paper>
    
    <SnackSaved open={savedOpen} setOpen={setSavedOpen} />
  </Page>;
}
