export const LOGIN_URL = '/v3/users/login';
// export const LOGOUT_URL = '/v3/users/logout';
export const LOGOUT_URL = '/users/logout';
// export const RENEW_URL = '/v3/users/renew_sign_in';
export const RENEW_URL = '/users/renew_sign_in';
//
export const loginFields = [
  'id', 'first_name', 'last_name', 'auth_token', 'email', 'is_admin', 'extra_data', 'get_item_thumb', 'type', 'settings', 'goal_weight',
  'practice|id/name/item_is_square/get_medium', 'full_move_groups', 'full_eat_groups', 'calendar_url', 'ehealth', 'country_id', 'is_secretary'
];

export type User = {
  id?: number;
  auth_token: string;
  created_at?: string;
  email: string;
  first_name?: string;
  second_name?: string;
  last_name?: string;
  call_name?: string;
  spouse_name?: string;
  type?: string;
  practice_id?: number;
  goal_weight?: number;
  calendar_url?: string;
  country_id?: number;
  language_id?: number;
  birth_date?: string;
  phone_mobile?: string;
  phone_fixed?: string;
  password?: string;
  password_repeat?: string;
  is_agreed?: boolean;
  is_agreed_practice?: boolean;
  enabled?: boolean;
  consent_status?: {
    enabled: boolean;
    is_basic?: boolean;
    is_intake?: boolean;
    is_measure_weight?: boolean;
    is_diary_eat?: boolean;
    is_diary_move: boolean;
  };
  is_secretary?: boolean;
  gender?: number;
  national_number?: string;

  street?: string;
  number?: string;
  zip?: string;
  city?: string;

  item?: File;

  full_eat_groups?: {
    [z:string]: {
      profile: {
        name: string,
        questions: string[]
      },
      practices: [id:number, name:string][]
    }
  };

  full_move_groups?: {
    [z:string]: {
      profile: {
        name: string,
        questions: string[]
      },
      practices: [id:number, name:string][]
    }
  };

  ehealth?: {
    connect: string;
    status: {
      key: string;
      stamp: string;
    }
  }

  extra_data?: {
    dashboard?: {
      hide_weight?: boolean;
    },
    emails?: {
      account?: boolean;
      newsletter?: boolean;
      nutri_appointment?: boolean;
      nutri_mail?: boolean;
      nutri_workshop?: boolean;
    },
    nutri_mail?: {
      signature?: string;
    },
  };

  settings?: {
    dashboard?: {
      hide_weight?: boolean;
    },
    nutri_advice?: {
      ignore_intake?: boolean;
    },
    nutri_mail?: {
      signature?: string;
    },
    is_background_colour?: boolean;
    background_colour?: string;
    notifications?: {
      account?: boolean;
      newsletter?: boolean;
      nutri_appointment?: boolean;
      nutri_mail?: boolean;
      nutri_workshop?: boolean;
    }
  }

  coach_skills: {
    id: number;
    name: string;
  }[];
  profile_text?: string;

  readonly get_item_thumb?: string;
  readonly get_medium?: string;
  readonly get_bg_medium?: string;

  readonly practice_get_medium?: string;
  readonly practice_name?: string;
  readonly practice_item_is_square?: boolean;

  readonly full_name?: string;
}
