import React, { FunctionComponent, useEffect, useState } from 'react';
// import { useTheme } from '@mui/material/styles';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
import { fetch_all, fetch_one } from "../../lib/v31lib";
import { loginAtom } from '../../lib/auth';
import { currentPracticeAtom } from '../../lib/practice';

import {
  CircularProgress,
  Divider,
  Grid,
  MenuItem,
  Paper,
} from '@mui/material';

import {
  Button,
  DatePicker,
  Page,
  Select,
  Typography
} from '../../components/v2/styled';
import { SnackSaved } from '../../components/v2/snacks/Saved';

import { Location } from '../../models/Location';
import { Export } from '../../models/Export';
import { User } from '../../models/User';

const { DateTime } = require("luxon");

type Props = {}

export const CoachExportEdit: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  // const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  const params = useParams();
  const queryClient = useQueryClient();
  
  const [objectId, setObjectId] = useState<string | undefined>(params.id);
  const [savedOpen, setSavedOpen] = useState<boolean>(false);
  const [mainObject, setMainObject] = useState<Export>({});

  const [errors, setErrors] = useState<{[z:string]: boolean}>({});

  const {
    data: coaches,
    isLoading: coachesLoading,
    isError: coachesError,
    isSuccess: coachesSuccess,
    // refetch: coachesRefetch,
  } = useQuery({
    queryKey: ["coaches", currentPractice?.id],
    queryFn: () =>
      fetch_all<User>(
        `/nl/v3/objects/fetch_all`,
        {
          object: 'coach',
          fields: ['id', 'full_name'],
          order: "first_name ASC, last_name ASC",
          filter: {
            search: '',
            advanced: {
              practice_id: (currentPractice?.id || login?.practice_id),
              is_secretary: '0',
              enabled: '1'
            }
          }
        },
        login
      ),
    enabled: !!currentPractice?.id
  });
  const {
    data: locations,
    isLoading: locationsLoading,
    isError: locationsError,
    isSuccess: locationsSuccess,
    // refetch: locationsRefetch,
  } = useQuery({
    queryKey: ["locations", currentPractice?.id],
    queryFn: () =>
      fetch_all<Location>(
        `/nl/v3/objects/fetch_all`,
        {
          object: 'location',
          fields: ['id', 'name', 'colour'],
          filter: {
            search: '',
            advanced: {
              practice_id: (currentPractice?.id || login?.practice_id),
              enabled: '1'
            }
          }
        },
        login
      ),
    enabled: !!currentPractice?.id
  });
  const {
    data: object,
    isLoading: objectLoading,
    isError: objectError,
    isSuccess: objectSuccess,
    refetch: objectRefetch,
  } = useQuery({
    queryKey: ["export", (currentPractice?.id || login?.practice_id), objectId],
    queryFn: () =>
      fetch_one<Export>(
        `/nl/v3/objects/fetch_all`,
        {
          object: 'export',
          id: objectId,
          fields: [
            'id', 'settings', 'type'
          ]
        },
        login
      ),
    enabled: !!(currentPractice?.id || login?.practice_id) && parseInt(objectId || '', 10) > 0,
  });







  const mutationSave = useMutation({
    mutationFn: (pars: {
      formData: any;
      keepOpen?: boolean
    }) => {
      return fetch_one<Export>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/save`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      setSavedOpen(true);
      queryClient.invalidateQueries({ queryKey: ["coach", "exports", (currentPractice?.id || login?.practice_id)] });
      navigate("/exports");
    },
  });






  
  let page_title;
  if (typeof((object || {}).id) === 'undefined') {
    page_title = t("general.titles.add", "Nieuwe") + " " + t("coach.models.exports.singular", "Export");
  } else {
    page_title = t("general.titles.edit", "Wijzig") + " " + t("coach.models.exports.singular", "Export");
  }

  useEffect(() => {
    if (params.id !== 'new') objectRefetch();
  }, [params.id]);
  useEffect(() => {
    if (!!objectSuccess) setMainObject(object);
  }, [object]);
  useEffect(() => {
    let err:any = {};
    if (!mainObject.type) err.type = true;
    if (!!mainObject.type) {
      if (mainObject.type === "Export::Appointment") {
        if (!!mainObject.settings?.start && !!mainObject.settings?.end && DateTime.fromFormat(mainObject.settings?.start, "dd/LL/y") > DateTime.fromFormat(mainObject.settings?.end, "dd/LL/y")) err.end = true;
      }
    }

    setErrors(err);
  }, [mainObject]);

  return <Page
    title={page_title}
    titleCrumbs={[
      {
        title: t("coach.menu.exports", "Exports"),
        path: "/exports"
      }
    ]}
  >
    <Paper sx={{padding: 2}}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Select
            fullWidth
            caption={t("coach.models.exports.type", "Type")}
            starred
            displayEmpty
            id="location"
            value={mainObject.type || ''}
            setValue={(v) => {
              setMainObject({
                ...mainObject,
                type: v.toString()
              });
            }}
          >
            <MenuItem value="">--- {t("coach.models.exports.placeholders.type", "Selecteer het type export")} ---</MenuItem>
            <MenuItem value="Export::Appointment">{t("shared.options.export_types.appointment")}</MenuItem>
            <MenuItem value="Export::User">{t("shared.options.export_types.user")}</MenuItem>
          </Select>
        </Grid>

        {mainObject.type === "Export::Appointment" && <Grid item xs={12} md={6}>
          <Typography sx={{fontSize: '0.7rem'}}>{t("coach.models.exports.setting_start")}</Typography>
          <DatePicker
            openTo="day"
            label=""
            format={"dd/LL/y"}
            // caption=''
            views={["year", "month", "day"]}
            setValue={(e) => {
              setMainObject({
                ...mainObject,
                settings: {
                  ...(mainObject?.settings || {}),
                  start: e
                }
              });
            }}
            value={mainObject?.settings?.start || ''}
            id="settings_start"
            sx={{
              box: {
                width: 'auto',
                display: 'inline-block',
                marginRight: 0.5
              },
              field_box: {
                "svg": {
                  display: 'none'
                }
              },
              field: {
                width: 'auto',
                marginTop: 0,
                "input": {
                  textAlign: 'center'
                }
              }
            }}
          />
        </Grid>}
        {mainObject.type === "Export::Appointment" && <Grid item xs={12} md={6}>
          <Typography sx={{fontSize: '0.7rem'}}>{t("coach.models.exports.setting_end")}</Typography>
          <DatePicker
            openTo="day"
            label=""
            format={"dd/LL/y"}
            // caption=''
            views={["year", "month", "day"]}
            setValue={(e) => {
              setMainObject({
                ...mainObject,
                settings: {
                  ...(mainObject?.settings || {}),
                  end: e
                }
              });
            }}
            value={mainObject?.settings?.end || ''}
            id="settings_end"
            sx={{
              box: {
                width: 'auto',
                display: 'inline-block',
                marginRight: 0.5
              },
              field_box: {
                "svg": {
                  display: 'none'
                }
              },
              field: {
                width: 'auto',
                marginTop: 0,
                "input": {
                  textAlign: 'center'
                }
              }
            }}
          />
        </Grid>}
        {mainObject.type === "Export::Appointment" && <Grid item xs={12}>
          <Select
            fullWidth
            caption={t("coach.models.exports.scope", "Scope")}
            displayEmpty
            id="scope"
            value={mainObject.settings?.scope || ''}
            setValue={(v) => {
              setMainObject({
                ...mainObject,
                settings: {
                  ...(mainObject?.settings || {}),
                  scope: v.toString()
                }
              });
            }}
          >
            <MenuItem value="">--- {t("coach.models.exports.placeholders.setting_scope", "Selecteer de scope export")} ---</MenuItem>
            <MenuItem value="practice">{t("shared.options.scopes.all")}</MenuItem>
            {(coaches || []).map(coach => <MenuItem value={coach.id}>{coach.full_name}</MenuItem>)}
          </Select>
        </Grid>}
        {mainObject.type === "Export::Appointment" && <Grid item xs={12} md={6}>
          <Select
            fullWidth
            caption={t("coach.models.exports.setting_location", "Locatie")}
            displayEmpty
            id="location"
            value={mainObject.settings?.location || ''}
            setValue={(v) => {
              setMainObject({
                ...mainObject,
                settings: {
                  ...(mainObject?.settings || {}),
                  location: v.toString()
                }
              });
            }}
          >
            <MenuItem value="">{t("shared.options.locations.all")}</MenuItem>
            {(locations || []).map(location => <MenuItem value={location.id}>{location.name}</MenuItem>)}
          </Select>
        </Grid>}
        {mainObject.type === "Export::Appointment" && <Grid item xs={12} md={6}>
          <Select
            fullWidth
            caption={t("coach.models.exports.setting_yellow", "Afspraakinstelling")}
            displayEmpty
            id="yellow"
            value={mainObject.settings?.yellow || ''}
            setValue={(v) => {
              setMainObject({
                ...mainObject,
                settings: {
                  ...(mainObject?.settings || {}),
                  yellow: v.toString()
                }
              });
            }}
          >
            <MenuItem value="">--- {t("coach.models.exports.placeholders.setting_yellow", "Selecteer de instelling")} ---</MenuItem>
            <MenuItem value="all">{t("shared.options.export_yellow.all")}</MenuItem>
            <MenuItem value="none">{t("shared.options.export_yellow.none")}</MenuItem>
            <MenuItem value="only">{t("shared.options.export_yellow.only")}</MenuItem>
          </Select>
        </Grid>}




        <Grid item xs={12} sx={{
          alignContent: 'baseline',
          textAlign: 'center',
          position: 'sticky',
          bottom: '-24px',
          backgroundColor: 'white',
          zIndex: 1201
        }}>
          <Divider sx={{marginTop: 1, marginBottom: 0}} />

          {!!mutationSave.isPending && <CircularProgress />}
          {!mutationSave.isPending && <Button
            label={t("shared.buttons.save")}
            id='save'
            disabled={Object.keys(errors).length > 0}
            contained
            onClick={(v) => {
              mutationSave.mutate({
                formData: {
                  object: 'export',
                  fields: ['id'],
                  handler_id: login?.id,
                  ob: {
                    ...mainObject,
                    practice_id: currentPractice?.id,
                    user_id: login?.id
                  }
                }
              });
            }}
            sx={{marginBottom: 1, marginTop: 1}}
          />}
          {!mutationSave.isPending && <Button
            label={t("shared.buttons.cancel")}
            id='cancel'
            text
            // contained
            onClick={(v) => {
              navigate("/exports");
            }}
            sx={{marginLeft: 1, marginBottom: 1, marginTop: 1}}
          />}
        </Grid>
      </Grid>
    </Paper>
    
    <SnackSaved open={savedOpen} setOpen={setSavedOpen} />
  </Page>;
}
