import React, { FunctionComponent, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from "@tanstack/react-query";
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next'
// import useMediaQuery from '@mui/material/useMediaQuery';

// import { check_subdomain } from '../../lib/server_helper';
import { fetch_one, fetch_all, fetch_all_with_count } from "../../lib/v31lib";
import { loginAtom } from '../../lib/auth';
import { currentPracticeAtom } from '../../lib/practice';

import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DeleteIcon from '@mui/icons-material/Delete';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import EditIcon from '@mui/icons-material/Edit';

import {
  Avatar,
  Box,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText
} from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';

import {
  Card,
  DataGrid,
  Dt,
  Fab,
  IconButton,
  Page,
  Typography
} from '../../components/v2/styled';
import { SnackDestroyed } from '../../components/v2/snacks/Destroyed';
import { SnackSaved } from '../../components/v2/snacks/Saved';

import { Export } from '../../models/Export';

// const { DateTime } = require("luxon");

type Props = {}

export const CoachExports: FunctionComponent<Props> = ({}) => {
  const [login, setLogin] = useAtom(loginAtom);
  const [currentPractice, setCurrentPractice] = useAtom(currentPracticeAtom);
  const theme = useTheme();
  const navigate = useNavigate();
  const {t, i18n} = useTranslation(['translations']);
  const [destroyedOpen, setDestroyedOpen] = useState<boolean>(false);
  const [activeForDestroy, setActiveForDestroy] = useState<Export>();
  const [destroyConfirmOpen, setDestroyConfirmOpen] = useState<boolean>(false);
  const [savedOpen, setSavedOpen] = useState<boolean>(false);
  
  let count = 0;
  const [page, setPage] = useState<number>(0);
  const [order, setOrder] = useState<string>("id DESC");

  const {
    data: objects,
    isLoading: objectsLoading,
    isError: objectsError,
    isSuccess: objectsSuccess,
    refetch: objectsRefetch,
  } = useQuery({
    queryKey: ["coach", "exports", (currentPractice?.id || login?.practice_id), {page: page, order: order}],
    queryFn: () =>
      fetch_all_with_count<Export>(
        `/nl/v3/objects/fetch_all`,
        {
          object: 'export',
          fields: ['id', 'get_item', 'created_at', 'started_at', 'finished_at', 'type', 'user|id/full_name'],
          order: order,
          per_page: 20,
          page: page,
          filter: {
            search: '',
            advanced: {
              practice_id: (currentPractice?.id || login?.practice_id)
            }
          }
        },
        login
      ),
    select: (d) => {
      count = d.count;
      return d.result;
    },
    enabled: !!(currentPractice?.id || login?.practice_id),
  });








  const mutationCustomAction = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<Export>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/custom_action`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      objectsRefetch();
    },
  });
  const mutationDestroy = useMutation({
    mutationFn: (pars: {
      formData: any;
    }) => {
      return fetch_one<Export>(
        `/${i18n.resolvedLanguage || 'nl'}/v3/objects/remove`,
        pars.formData,
        login
      );
    },
    onMutate: (pars: {
      formData: any;
    }) => {
      return true;
    },
    onError: (data, variables, context) => {
      return true;
    },
    onSuccess: (data, variables, context) => {
      setDestroyedOpen(true);
      setActiveForDestroy(undefined);
      objectsRefetch();
    },
  });







  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 80 },
    { field: 'user_full_name', headerName: t('coach.models.exports.user'), width: 200 },
    { field: 'type', headerName: t('coach.models.exports.type'), width: 200 },
    { field: 'created_at', headerName: t('coach.models.exports.created_at'), width: 200, renderCell: params => <>
      <Dt d={params.row.created_at} f="DDD t" />
    </> },
    { field: 'started_at', headerName: t('coach.models.exports.started_at'), width: 200, renderCell: params => <>
      <Dt d={params.row.started_at} f="DDD t" />
    </> },
    { field: 'finished_at', headerName: t('coach.models.exports.finished_at'), width: 200, renderCell: params => <>
      <Dt d={params.row.finished_at} f="DDD t" />
    </> },
  ];

  return <Page title={t("coach.menu.exports", "Exports")}>
    <DataGrid
      action_count={3}
      actions={(params) => {
        return (<>
          {!params.row.started_at && <IconButton title="Open Detail" color="default" path={`/export/${params.row.id}`}>
            <EditIcon />
          </IconButton>}
          <IconButton title="Remove" destroyBtn color="default" onClick={() => {
            mutationDestroy.mutate({
              formData: {
                object: 'export',
                id: params.row.id,
                ob: {
                  id: params.row.id,
                }
              }
            });
          }}>
            <DeleteIcon />
          </IconButton>
          {!params.row.started_at && <IconButton title="Run it" color="default" onClick={() => {
            mutationCustomAction.mutate({
              formData: {
                object: 'export',
                class_action: 'custom_api_run_it',
                id: params.row.id
              }
            });
          }}>
            <DirectionsRunIcon />
          </IconButton>}
          {!!params.row.get_item && <IconButton title="Download" color="default" onClick={() => {
            window.open(params.row.get_item, "_BLANK");
          }}>
            <CloudDownloadIcon />
          </IconButton>}
        </>);
      }}
      objectCount={count}
      getRowClassName={(params) => `datagrid-row-enabled-${params.row.enabled}`}
      objects={(objects || []).map(u => ({
        ...u
      }))}
      columns={columns}
      loader={objectsLoading}
      onSortModelChange={(a, b) => {
        let a_0_field:string = a[0].field;
        if (a_0_field === 'name') a_0_field = `last_name ${a[0].sort}, first_name`;
        setOrder([a_0_field, a[0].sort].join(' '));
      }}
      page={page}
      setPage={(p) => {setPage(p);}}
    />

    <SnackDestroyed open={destroyedOpen} setOpen={setDestroyedOpen} />
    <SnackSaved open={savedOpen} setOpen={setSavedOpen} />

    <Fab
      onClick={() => {
        navigate("/export/new", {});
      }}
    />
  </Page>;
}
